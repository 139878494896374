<template>
  <div
    class="bg-white p-0 m-0"
  >
    <div class="p-2 d-flex justify-content-between align-items-center">
      <span style="color: #5E5873; font-weight: 500; font-size: 16px">Manhours Report
      </span>
      <!-- <div class="btn btn-primary">
        + Add Record
      </div> -->
    </div>
    <hr class="">
    <b-card-body>
      <div class="d-flex justify-content-between  flex-wrap">
        <div class="d-flex align-items-center">
          <span class="mr-1">
            Show
          </span>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </div>

        <!-- sorting  -->
        <!-- <b-form-group
          label="Sort"
          label-size="sm"
          label-align-sm="left"
          label-cols-sm="2"
          label-for="sortBySelect"
          class="mr-1 mb-md-0"
        >
          <b-input-group
            size="sm"
          >
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
            >
              <template #first>
                <option value="">
                  none
                </option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
            >
              <option :value="false">
                Asc
              </option>
              <option :value="true">
                Desc
              </option>
            </b-form-select>
          </b-input-group>
        </b-form-group> -->

        <!-- filter -->

        <div class="d-flex align-items-center">
          <span class="mr-1">Search</span>
          <b-input-group
            size="md"
          >
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              class="mr-2"
              placeholder="Type to Search"
            />
          </b-input-group>
          <!-- <b-input-group-append> -->
          <div
            class="btn btn-primary pl-2"
            style="width: 90%;"
            @click="showFormsManHour"
          >
            <span class="p-2 mt-3">+ Add Record</span>
          </div>
          <!-- </b-input-group-append> -->

        </div>
      </div>
    </b-card-body>

    <b-table
      striped
      hover
      responsive
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <template #cell(name)="data">
        <div class="d-flex align-items-center">
          <b-avatar :src="data.value" />
          <span class="ml-2 font-weight-bold"> {{ data.item.team.name }}</span>
        </div>
      </template>
      <template #cell(status)="data">
        <b-badge :variant="statusVariant(data.item.status)">
          {{ data.item.status }}
        </b-badge>
      </template>
      <!-- <template #cell(actions)="data">
        <div class="d-flex justify-content-center align-items-center">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25 purpleColor"
              />
            </template>
            <b-dropdown-item>
              <feather-icon
                icon="Edit2Icon"
                class="mr-50"
              />
              <span>Edit</span>
            </b-dropdown-item>
            <b-dropdown-item @click="deleteRecord(data.item.id)">
              <feather-icon
                icon="TrashIcon"
                class="mr-50"
              />
              <span> Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template> -->
    </b-table>
    <div class="d-flex justify-content-between align-items-center ml-3 mr-3 mb-2">
      <span style="color: #B9B9C3">Showing 1 to {{ perPage }} of {{ items.length }} entries</span>
      <b-pagination
        v-model="currentPage"
        :value="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        first-number
        last-number
        align="right"
        prev-class="prev-item"
        next-class="next-item"
        class="mt-1 mb-0"
        @input="(value)=>props.pageChanged({currentPage:value})"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </div>
    <div>
      <b-modal
        id="modal-delete-record-confirm"
        centered
        hide-footer
      >
        <p class="my-1 font-weight-bold">
          Confirm to delete this record?
        </p>
        <div class="float-right">
          <b-button
            variant="danger"
            class="btn-wishlist mr-1"
            @click="confirmDel"
          >
            <span>Delete</span>
          </b-button>
          <b-button
            variant="light"
            class="btn-wishlist"
            @click="$bvModal.hide('modal-delete-confirm')"
          >
            <span>Cancel</span>
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BTable, BAvatar, BBadge, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody,
} from 'bootstrap-vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
// BInputGroupAppend

export default {
  components: {
    // BDropdown,
    // BDropdownItem,
    BTable,
    BAvatar,
    BBadge,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
  },
  data() {
    return {
      perPage: 5,
      deleteRec: 0,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'name', label: 'Project Team', sortable: true },
        { key: 'startDate', label: 'Start Date', sortable: true },
        { key: 'endDate', label: 'End Date', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'planningHours', label: 'Planned Hours', sortable: true },
        { key: 'actualHours', label: 'Actual Hours', sortable: true },
      ],
      /* eslint-disable global-require */
      items: [
      ],
      /* eslint-disable global-require */
      // status: [{
      //   1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      // },
      // {
      //   1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      // }],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Overdue: 'light-danger',
        Completed: 'light-success',
        Pending: 'light-warning',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    this.$http.get('/analytics/data')
      .then(response => {
        const dashboardData = response.data
        this.items = dashboardData.listProjectTeam.projectTeam
        this.totalRows = this.items.length
      })
  },
  methods: {
    showFormsManHour() {
      console.log('test')
      this.$emit('showFormsManhour', true)
    },
    deleteRecord(data) {
      // console.log(data)
      this.deleteRec = data
      this.$bvModal.show("modal-delete-record-confirm")
    },
    confirmDel() {
      const timeout = 4000
      this.items.forEach((element, idx) => {
        console.log(element.id)
        console.log(this.deleteRec)
        if (element.id === this.deleteRec) {
          this.items.splice(idx, 1)
        }
      })
      this.$toast(
        {
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Info`,
            icon: "CoffeeIcon",
            variant: "success",
            text: `Record successfully deleted!`,
          },
        },
        { timeout },
      )
      this.$bvModal.hide("modal-delete-record-confirm")
      console.log(this.items)
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
