<template>
  <div>
    <div>
      <div style="height: inherit">
        <div v-if="showReport">
          <div class="card">
            <div class="card-header">
              <span style="color: #5E5873; font-weight: 500px; font-size: 18px;">Project Details</span>
            </div><hr>
            <div class="p-2 row">
              <div class="col-6">
                <b-form-group
                  label="Project Name"
                  label-for="employee-name"
                >
                  <b-form-input
                    id="employee-name"
                  />
                </b-form-group>
                <label>Start Date</label>
                <input
                  type="date"
                  class="form-control"
                >
                <!-- <b-form-input
                    id="employee-number"
                  /> -->
                <!-- </input> -->
              </div>
              <div class="col-6">
                <b-form-group
                  label="Project Description"
                  label-for="department"
                >
                  <b-form-input
                    id="department"
                  />
                </b-form-group>
                <label>End Date</label>
                <input
                  type="date"
                  class="form-control"
                >
              </div>
              <div class="col-6">
                <b-form-group
                  label="Cost Weightage (%)"
                  label-for="employee-name"
                >
                  <b-form-input
                    id="employee-name"
                  />
                </b-form-group>
                <b-form-group
                  label="Planned Manhours (%)"
                  label-for="employee-number"
                >
                  <b-form-input
                    id="employee-number"
                  />
                </b-form-group>
              </div>
              <div class="col-6">
                <b-form-group
                  label="Manhours Weightage (%)"
                  label-for="department"
                >
                  <b-form-input
                    id="department"
                  />
                </b-form-group>
                <b-form-group
                  label="Actual Manhours"
                  label-for="phone-number"
                >
                  <b-form-input
                    id="phone-number"
                  />
                </b-form-group>
              </div>
              <div class="col-12">
                <span style="color: #00AE9D;"> + Add Cut Off Date</span>
              </div>
            </div>
          </div>

          <div class="row pl-1">
            <div
              style="width: 170px"
              class="mr-1"
            >
              <b-button
                block
                class="mt-1"
              >
                Save Changes
              </b-button>
            </div>
            <div style="width: 130px;">
              <button
                type="button"
                class="mt-1 btn btn-outline-secondary"
                @click="showReport = false"
              >
                Discard
              </button>
            </div>
          </div>
        </div>
        <div v-else>
          <project-team
            class="col-12 mt-3"
            @showFormsManhour="showFormManhours"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput, BButton,
} from 'bootstrap-vue'
import ProjectTeam from '@/views/pages/dashboard/analytic/component/ProjectTeam.vue'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    ProjectTeam,
    // vSelect,
  },
  data() {
    return {
      showReport: false,
      optionsPoliceNoti: [
        { text: 'Yes', value: 'first' },
        { text: 'No', value: 'second' },
      ],
      optionsType: [
        { text: 'Injury - First Aid', value: 'orange' },
        { text: 'Injury - Medical / Emergency Treatment', value: 'apple' },
        { text: 'Property Damage', value: 'pineapple' },
        { text: 'Equipment Failure', value: 'grape' },
        { text: 'Theft', value: 'pineapple' },
        { text: 'Others', value: 'grape' },
      ],
      optionsRoot: [
        { text: 'Behavior', value: 'orange' },
        { text: 'Procedure', value: 'apple' },
        { text: 'Equipment', value: 'pineapple' },
        { text: 'Conditions', value: 'grape' },
        { text: 'PPE', value: 'pineapple' },
        { text: 'Others', value: 'grape' },
      ],
    }
  },
  methods: {
    showFormManhours(val) {
      console.log(val)
      this.showReport = val
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
